<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="$router.back()">工单管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">新增工单</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="120px"
              label-position="right"
              class="demo-ruleForm"
            >
              <el-form-item label="工单编号" prop="orderNo">
                <el-input
                  v-model="ruleForm.orderNo"
                  size="small"
                  maxlength="80"
                  disabled
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item label="问题类型" prop="orderQuestionType">
                <el-select
                  v-model="ruleForm.orderQuestionType"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in workorderquestionType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
               <el-form-item label="提出单位类型" prop="orderCompanyType">
                <el-select
                  v-model="ruleForm.orderCompanyType"
                  placeholder="请选择"
                  size="small"
                  @change="orderCompanyTypeChange"
                >
                  <el-option
                    v-for="item in workordercompanyType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="提出单位名称"
                prop="orderCompanyName"
                :rules="[
                  {
                    required:
                      ruleForm.orderCompanyType == '10' ||
                      ruleForm.orderCompanyType == '20'
                        ? true
                        : false,
                    message: '请输入提出单位名称',
                    trigger: 'blur',
                  },
                ]"
              >
                <!-- <el-input
                  v-model="ruleForm.orderCompanyName"
                  size="small"
                  show-word-limit
                ></el-input> -->
                 <el-select
            size="small"
            v-model="ruleForm.orderCompanyName"
            remote
            :remote-method="getCompanyList"
            filterable
            clearable
            placeholder="请至少输入两个字搜索"
          >
            <el-option
              v-for="item in companyList"
              :key="item.compName"
              :label="item.compName"
              :value="item.compName"
            ></el-option>
          </el-select>
              </el-form-item>
              <el-form-item label="行政区划" prop="areaId">
                <el-cascader
                  v-model="ruleForm.areaId"
                  :options="areatreeList"
                  :props="propsarea"
                  clearable
                  filterable
                  size="small"
                ></el-cascader>
              </el-form-item>
              <el-form-item label="工单类型" prop="orderType">
                <el-select
                  v-model="ruleForm.orderType"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in workorderType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
             
              <el-form-item
                label="提出人"
                prop="orderPersonName"
               
              >
                <el-input
                  v-model="ruleForm.orderPersonName"
                  size="small"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="提出人电话"
                prop="orderPersonPhone"
                :rules="[
                  {
                    required:false,
                    validator: Phone,
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  v-model="ruleForm.orderPersonPhone"
                  size="small"
                ></el-input>
              </el-form-item>

              <el-form-item label="工单内容" prop="chatContent">
                <el-input
                  type="textarea"
                  v-model="ruleForm.chatContent"
                  size="small"
                  maxlength="500"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item label="上传附件" prop="fileName" class="fileName">
                <div style="display:flex;align-items: center;">
                  <el-upload
                    class="upload-demo upload-btn"
                    :action="actionUrl"
                    :on-error="handleError"
                    :on-success="handleSuccess"
                    :on-change="uploadChange"
                    multiple
                    :limit="5"
                    :show-file-list="false"
                    :auto-upload="false"
                  >
                    <el-button class="bgc-bv" size="mini">上传附件</el-button>
                  </el-upload>
                  <span style="color:#f46173;margin-left:10px"
                    >支持上传word、excel、文本、图片、pdf等，文件大小不超过3M，最多上传5个文件。</span
                  >
                </div>

                <div style="display:flex;flex-direction: column;">
                  <span
                    style="color:#409EFF"
                    v-for="(item, index) in ruleForm.NameList"
                    :key="index"
                  >
                    {{ item.name }}
                    <!-- <a @click="reomveExl" style="color: #5bb5ff;margin:0 15px">预览</a> -->
                    <a
                      @click="reomveExl(index)"
                      style="color: red;margin:0 15px"
                      >删除</a
                    >
                  </span>
                </div>
              </el-form-item>
            </el-form>
            <div class="btn-box flexcc">
              <el-button @click="doCancel" class="bgc-bv">取 消</el-button>
              <el-button class="bgc-bv" @click="doAddSave('ruleForm')"
                >确 定</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "agentAdd",
  components: {},
  data() {
    return {
      id: "",
      stu: "add",
      workorderType: [],
      workorderquestionType: [],
      workordercompanyType: [],
      companyList:[],
      ruleForm: {
        orderQuestionType: "",
        areaId: "",
        orderType: "",
        orderCompanyType: "",
        orderCompanyName: "",
        orderPersonName: "",
        orderPersonPhone: "",
        fileName: [],
        NameList: [],
        chatFileKeys: [],
      },
      areatreeList: [],
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      rules: {
        orderQuestionType: [
          { required: true, message: "请选择问题类型", trigger: "change" },
        ],
        areaId: [
          { required: true, message: "请选择行政区划", trigger: "change" },
        ],
        orderType: [
          { required: true, message: "请选择工单类型", trigger: "change" },
        ],
        orderCompanyType: [
          { required: true, message: "请选择提出单位类型", trigger: "change" },
        ],
        chatContent: [
          { required: true, message: "请输入工单内容", trigger: "blur" },
        ],
      },
    };
  },
  computed: {},
  created() {
    this.stu = this.$route.query.stu;
    this.getareatree();
    this.getdataList();
    if (this.stu == "edit") {
      this.getInfo(this.$route.query.id);
    }
  },
  methods: {
    Phone(rule, value, callback) {
     
  
        if (
          value &&
          !/^1[3456789]\d{9}$/.test(value) &&
          !/^([0-9]{3,4}-)?[0-9]{7,8}$/.test(value)
        ) {
          callback(new Error("请输入正确的联系电话"));
        } else {
          callback();
        }
    },
    getdataList() {
      const workorderquestionType = this.$setDictionary(
        "WORK_ORDER_QUESTION_TYPE",
        "list"
      );
      const workordercompanyType = this.$setDictionary(
        "WORK_ORDER_COMPANY_TYPE",
        "list"
      );
      const workorderType = this.$setDictionary("WORK_ORDER_TYPE", "list");
      const list = [];
      const workordercompanyTypelist = [];
      const workorderTypelist = [];
      for (const key in workorderquestionType) {
        list.push({
          value: key,
          label: workorderquestionType[key],
        });
      }
      for (const key in workordercompanyType) {
        workordercompanyTypelist.push({
          value: key,
          label: workordercompanyType[key],
        });
      }
      for (const key in workorderType) {
        workorderTypelist.push({
          value: key,
          label: workorderType[key],
        });
      }
      this.workorderquestionType = list;
      this.workordercompanyType = workordercompanyTypelist;
      this.workorderType = workorderTypelist;
    },
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        let data = ret.data || [];
        data.map((el) => {
          if (el.children) {
            el.children.map((els) => {
              els.disabled = false;
              if (els.children) {
                els.children.map((els1) => {
                  els1.disabled = false;
                });
              }
            });
          }
        });
        this.areatreeList = data;
      });
    },
    /**
     * 新增
     */
    doAddSave(formName) {
      const params = {
        orderQuestionType: this.ruleForm.orderQuestionType,
        orderAreaId: this.ruleForm.areaId,
        orderType: this.ruleForm.orderType,
        orderCompanyType: this.ruleForm.orderCompanyType,
        orderCompanyName: this.ruleForm.orderCompanyName,
        orderPersonName: this.ruleForm.orderPersonName,
        orderPersonPhone: this.ruleForm.orderPersonPhone,
        chatContent: this.ruleForm.chatContent,
        chatFileKeys: this.ruleForm.chatFileKeys,
      };
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$post("/repairOrder/work/order/insert/operating/", params)
            .then((res) => {
              if (res.status == "0") {
                this.$message({
                  type: "success",
                  message: "新增成功",
                });
                this.$router.push({
                  path: "/web/workOrder/workOrderList",
                  query: {
                    refresh: true,
                  },
                });
              }
            })
            .catch(() => {
              return;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getInfo(agencyId) {
      this.$post("/biz/mercantile/agency/getInfo", { agencyId })
        .then((ret) => {
          console.log(ret);
          this.ruleForm = {
            ...this.ruleForm,
            ...ret.data,
          };
          this.ruleForm.agencyIdcardFront = ret.data.agencyIdcardFrontUrl;
          this.ruleForm.agencyIdcardFrontKey = ret.data.agencyIdcardFront;
          this.ruleForm.agencyIdcardBack = ret.data.agencyIdcardBackUrl;
          this.ruleForm.agencyIdcardBackKey = ret.data.agencyIdcardBack;
          this.ruleForm.businessLicense = ret.data.businessLicenseUrl;
          this.ruleForm.businessLicenseKey = ret.data.businessLicense;
          this.ruleForm.legalIdcardBack = ret.data.legalIdcardBackUrl;
          this.ruleForm.legalIdcardBackKey = ret.data.legalIdcardBack;
          this.ruleForm.legalIdcardFront = ret.data.legalIdcardFrontUrl;
          this.ruleForm.legalIdcardFrontKey = ret.data.legalIdcardFront;
          this.ruleForm.cooperationTime = [
            ret.data.agencyStartDate.replaceAll("/", "-"),
            ret.data.agencyEndDate.replaceAll("/", "-"),
          ];
          this.ruleForm.areaId = ret.data.areaIds;
          this.ruleForm.trainTypeId = ret.data.trainTypeIds;
          this.ruleForm.companyId = ret.data.companyId;
        })
        .catch((err) => {
          return;
        });
    },
    // 点击取消
    doCancel() {
      this.$router.push({
        path: "/web/workOrder/workOrderList",
        query: {
          refrsh: true,
        },
      });
    },
    uploadChange(file) {
      let size = file.size / 1024 / 1024;
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (size > 3) {
        this.$message.error("文件大小不能超过3M");
        return;
      }

      let formData = new FormData();
      formData.append("folder ", "QUESTION");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);
      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.ruleForm.NameList.push({
            name: file.name,
            fileKey: result.data.fileKey,
          });
          this.ruleForm.chatFileKeys.push(
            result.data.fileKey + "_" + file.name
          );
        })
        .catch(() => {
          setTimeout(() => {
            this.$message({
              type: "warning",
              message: "保存失败",
            });
          }, 300);
        });
      this.$forceUpdate();
    },
    reomveExl(index) {
      this.ruleForm.NameList.splice(index, 1);
      this.ruleForm.chatFileKeys.splice(index, 1);
      this.$forceUpdate();
    },
    orderCompanyTypeChange(val) {
      this.val = val;
    },
     // 获取 - 提出方
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.companyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.companyList = [];
      }
    },
  },
};
</script>
<style lang="less">
.el-textarea {
  .el-textarea__inner {
    min-height: 10rem !important;
    resize: none;
  }
}
.upload-btn {
  width: 5rem;
  height: 28px;
  .el-upload {
    width: 5rem;
    height: 28px !important;
    border: none !important;
  }
}
</style>
